import { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import serverVars from 'server-vars';
import { IntlProvider } from 'dibs-react-intl';
import { RelayEnvironmentProvider } from 'react-relay';
import { PageSpinner } from 'dibs-elements/exports/PageSpinner';
import InternalLoginPage from '../../login/internal/InternalLoginPage';
import { LOGIN_FORM_TYPES } from '../../login/helpers/loginValidation';
import { getEnvironment } from '../../relayModernEnvironment';

import '../../login/styles/loginGlobalStyles.scss';

const formType: LOGIN_FORM_TYPES = serverVars.get('formType');

const root = createRoot(document.getElementById('js-root') as HTMLElement);
root.render(
    <RelayEnvironmentProvider environment={getEnvironment('internal')}>
        <IntlProvider locale="en-US">
            <Suspense fallback={<PageSpinner />}>
                <InternalLoginPage formType={formType} />
            </Suspense>
        </IntlProvider>
    </RelayEnvironmentProvider>
);
