import { FunctionComponent } from 'react';
import { Spinner } from '../Spinner';

import styles from './main.scss';

type PageSpinnerProps = {
    dataTn?: string;
};

export const PageSpinner: FunctionComponent<PageSpinnerProps> = props => {
    const { dataTn } = props;

    return (
        <div className={styles.container} data-tn={dataTn}>
            <Spinner />
        </div>
    );
};
