import { FunctionComponent, ReactNode } from 'react';
import { Well } from 'dibs-elements/exports/Well';
import styles from './styles/LoginWell.scss';

type Props = {
    children: ReactNode;
};

const LoginWell: FunctionComponent<Props> = ({ children }) => (
    <Well className={styles.loginWell}>{children}</Well>
);

export default LoginWell;
