import { FC, useEffect, useRef, useCallback, useState } from 'react';
import { useRelayEnvironment } from 'react-relay';
import serverVars from 'server-vars';

import { PageSpinner } from 'dibs-elements/exports/PageSpinner';

import { getGoToLocation } from '../helpers/loginGoTo';

import { internalLoginMutation } from '../internal/mutations/internalLoginMutation';

const OktaWidget: FC = () => {
    const environment = useRelayEnvironment();
    const [isLoading, setIsLoading] = useState(false);
    const widgetRef = useRef<HTMLDivElement | null>(null);
    const sessionExists = useRef(null);

    const onLogin = useCallback(
        async (accessToken: string): Promise<void> => {
            await internalLoginMutation(environment, {
                socialClientId: null,
                socialToken: accessToken,
                socialNetworkName: 'okta',
                marketingOptIn: false,
                cookieDomain: serverVars.get('cookieDomain'),
            });
            window.location.assign(getGoToLocation('/internal/'));
        },
        [environment]
    );

    const loginWithExistingSession = useCallback(async (): Promise<void> => {
        setIsLoading(true);
        const oktaWidget = window.OktaWidget;
        const response = await oktaWidget.authClient.token.getWithoutPrompt();
        await oktaWidget.authClient.tokenManager.setTokens(response.tokens);
        const accessToken = response?.tokens?.accessToken?.accessToken;
        onLogin(accessToken);
    }, [onLogin]);

    useEffect(() => {
        const init = async (): Promise<void> => {
            setIsLoading(true);
            sessionExists.current = await window.OktaWidget.authClient.session.exists();
            setIsLoading(false);

            if (sessionExists.current) {
                loginWithExistingSession();
            } else {
                const response = await window.OktaWidget.showSignInToGetTokens({
                    el: widgetRef.current,
                });

                const accessToken = response?.accessToken?.accessToken;
                await onLogin(accessToken);
            }
        };

        init();
    }, [widgetRef, onLogin, loginWithExistingSession]);

    return (
        <div>
            {isLoading && <PageSpinner />}
            <div ref={widgetRef} />
        </div>
    );
};

export default OktaWidget;
