import { FunctionComponent, useState, useEffect } from 'react';
import { Environment, QueryRenderer, graphql } from 'react-relay/legacy';
import { Well } from 'dibs-elements/exports/Well';
import Close from 'dibs-icons/exports/legacy/Close';
import { FormattedMessage } from 'dibs-react-intl';
import { localStorage } from 'dibs-browser-storage';
import { DealerLoginCookieDisclaimerQuery } from './__generated__/DealerLoginCookieDisclaimerQuery.graphql';

import styles from './styles/DealerLoginCookieDisclaimer.scss';
import getBuyerSupportLink from 'dibs-support-link/exports/getBuyerSupportLink';

type Props = {
    environment: Environment;
};

const CookieDisclaimer: FunctionComponent<Props> = ({ environment }) => {
    const [showCookieDisclaimer, dismissDisclaimer] = useState<boolean>(
        localStorage.getItem('showCookieDisclaimer') === undefined
    );

    useEffect(() => {
        if (!showCookieDisclaimer) {
            localStorage.setItem('showCookieDisclaimer', false);
        }
    }, [showCookieDisclaimer]);

    return (
        <QueryRenderer<DealerLoginCookieDisclaimerQuery>
            variables={{}}
            environment={environment}
            query={graphql`
                query DealerLoginCookieDisclaimerQuery {
                    viewer {
                        regionalInfo {
                            countryCode
                        }
                    }
                }
            `}
            render={({ props }) => {
                if (!props) {
                    return null;
                }
                const nonUSBasedRequest = props.viewer?.regionalInfo?.[0]?.countryCode !== 'US';

                const shouldDisplayCookieDisclaimer = !!(nonUSBasedRequest && showCookieDisclaimer);

                if (!shouldDisplayCookieDisclaimer) {
                    return null;
                }

                return (
                    <div className={styles.disclaimerContainer}>
                        <Well className={styles.disclaimerWell}>
                            <div className={styles.disclaimerContentContainer}>
                                <div>
                                    <FormattedMessage
                                        id="cookieDisclaimer.notice"
                                        defaultMessage="1stDibs uses cookies to ensure you get the best experience on our website. By continuing to browse, you agree to our {cookiePolicy}."
                                        values={{
                                            cookiePolicy: (
                                                <a
                                                    href={getBuyerSupportLink(
                                                        '/en-us/articles/14542897774875/'
                                                    )}
                                                >
                                                    <FormattedMessage
                                                        id="cookieDisclaimerLinkedText"
                                                        defaultMessage="cookie policy"
                                                    />
                                                </a>
                                            ),
                                        }}
                                    />
                                </div>
                                <div
                                    className={styles.disclaimerCloseButton}
                                    onClick={() => dismissDisclaimer(false)}
                                >
                                    <Close />
                                </div>
                            </div>
                        </Well>
                    </div>
                );
            }}
        />
    );
};
export default CookieDisclaimer;
