import { FunctionComponent } from 'react';
import { FormattedMessage } from 'dibs-react-intl';
import styles from './styles/LoginResetPasswordLink.scss';

type Props = {
    href: string;
};

const LoginResetPasswordLink: FunctionComponent<Props> = ({ href }) => (
    <a className={styles.forgotPWLink} href={href} data-tn={'forgot-pw-reset-link'}>
        <FormattedMessage id="login.forgot_password" defaultMessage="Forgot your password?" />
    </a>
);

export default LoginResetPasswordLink;
