import { FC } from 'react';
import { FormattedMessage } from 'dibs-react-intl';

const DealerLoginServerErrorMessage: FC = () => (
    <FormattedMessage
        defaultMessage="There was an error communicating with this service, please contact support."
        id="login.message.server_error"
    />
);

export default DealerLoginServerErrorMessage;
