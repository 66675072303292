import { FunctionComponent } from 'react';
import { FormattedMessage } from 'dibs-react-intl';

const DealerLoginServerErrorMessage: FunctionComponent = () => (
    <FormattedMessage
        defaultMessage="Your login credentials were incorrect, please try again."
        id="login.message.login_error"
    />
);

export default DealerLoginServerErrorMessage;
