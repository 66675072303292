/**
 * @generated SignedSource<<a0e3f6df7dec79cb1db90522f0c3eb3f>>
 * @relayHash ee30f45a82ccffca33d6580847bda2e8
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID app-admin-accounts/483.0.0-2024-10-09T08:55:07.793Z/DealerLoginCookieDisclaimerQuery

import { ConcreteRequest, Query } from 'relay-runtime';
export type DealerLoginCookieDisclaimerQuery$variables = Record<PropertyKey, never>;
export type DealerLoginCookieDisclaimerQuery$data = {
  readonly viewer: {
    readonly regionalInfo: ReadonlyArray<{
      readonly countryCode: string | null;
    } | null> | null;
  };
};
export type DealerLoginCookieDisclaimerQuery = {
  response: DealerLoginCookieDisclaimerQuery$data;
  variables: DealerLoginCookieDisclaimerQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "countryCode",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "DealerLoginCookieDisclaimerQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "RegionalInfo",
            "kind": "LinkedField",
            "name": "regionalInfo",
            "plural": true,
            "selections": [
              (v0/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "DealerLoginCookieDisclaimerQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "RegionalInfo",
            "kind": "LinkedField",
            "name": "regionalInfo",
            "plural": true,
            "selections": [
              (v0/*: any*/),
              (v1/*: any*/)
            ],
            "storageKey": null
          },
          (v1/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "app-admin-accounts/483.0.0-2024-10-09T08:55:07.793Z/DealerLoginCookieDisclaimerQuery",
    "metadata": {},
    "name": "DealerLoginCookieDisclaimerQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "a47d0cbb995042e814769d24929f9379";

export default node;
