import { FunctionComponent } from 'react';
import styles from './styles/InternalLoginDealerLink.scss';

const InternalLoginDealerLink: FunctionComponent = () => (
    <div className={styles.wrapper}>
        <a className={styles.link} href={'/login/dealer/'} data-tn={'forgot-pw-reset-link'}>
            Dealer Login
        </a>
    </div>
);

export default InternalLoginDealerLink;
