import { FC, ReactNode } from 'react';
import classNames from 'classnames';
import Checkmark from 'dibs-icons/exports/legacy/Checkmark';
import UploadFail from 'dibs-icons/exports/legacy/UploadFailed';
import styles from './styles/LoginMessage.scss';

export type LoginMessageType = 'success' | 'error';

type Props = {
    message?: ReactNode;
    messageType?: LoginMessageType;
};

const LoginMessage: FC<Props> = ({ message, messageType }) => {
    const isError = messageType === 'error';
    const MessageIcon = isError ? UploadFail : Checkmark;

    if (!message) {
        return null;
    }
    return (
        <div
            data-tn="login-message"
            className={classNames(styles.messageBanner, {
                [styles.error]: isError,
                [styles.success]: !isError,
            })}
        >
            <MessageIcon
                className={classNames(styles.messageIcon, {
                    [styles.failIcon]: isError,
                    [styles.checkmarkIcon]: !isError,
                })}
            />
            <span className={styles.message}>{message}</span>
        </div>
    );
};

export default LoginMessage;
