import { FC, ReactNode } from 'react';
import classNames from 'classnames';

import styles from './main.scss';

type WellProps = {
    className?: string;
    children?: ReactNode;
    label?: ReactNode;
    labelClassName?: string;
    hasClearfix?: boolean;
    hasPadding?: boolean;
    dataTn?: string;
};

export const Well: FC<WellProps> = ({
    className,
    children,
    label,
    labelClassName,
    hasClearfix = true,
    hasPadding = true,
    dataTn,
}) => {
    return (
        <div
            className={classNames(
                { clearfix: hasClearfix, [styles.padding]: hasPadding },
                className,
                styles.well
            )}
            data-tn={dataTn}
        >
            {label && <div className={classNames(labelClassName, styles.label)}>{label}</div>}
            {children}
        </div>
    );
};
