import { graphql, Environment } from 'react-relay/legacy';
import { commitMutationWithRecaptcha } from 'dibs-recaptcha/exports/commitMutationWithRecaptcha';

import {
    UserLoginInput,
    internalLoginMutation$data,
    internalLoginMutation as internalLoginMutationType,
} from './__generated__/internalLoginMutation.graphql';
import type { SupportedMFATypes } from 'dibs-recaptcha/exports/recaptcha';

const mutation = graphql`
    mutation internalLoginMutation($input: UserLoginInput!) {
        userLogin(input: $input) {
            __typename
        }
    }
`;

export type InternalLoginInput = Omit<UserLoginInput, 'authHash'>;

export async function internalLoginMutation(
    environment: Environment,
    input: InternalLoginInput
): Promise<internalLoginMutation$data> {
    const mutationInputVariables = {
        ...input,
        authHash: btoa((input.email || '').split('@')[0]),
    };

    const supportedMFATypes: SupportedMFATypes = ['CODE'];
    const executeCaptchaConfig = {
        action: 'login',
        userName: input.email,
        mfaVerificationCode: input?.mfaVerificationCode,
        supportedMFATypes,
        featureFlag: 'loginCaptcha',
        featureFlagV3: 'loginCaptchaV3',
    };
    return commitMutationWithRecaptcha<
        Partial<UserLoginInput>,
        internalLoginMutationType,
        internalLoginMutation$data
    >(environment, {
        mutation,
        mutationInputVariables,
        executeCaptchaConfig,
    });
}
