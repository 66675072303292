import { graphql, Environment } from 'react-relay/legacy';
import commitMutation from 'relay-commit-mutation-promise';
import {
    RequestPasswordEmailInput,
    internalForgotPasswordMutation$data,
    internalForgotPasswordMutation as internalForgotPasswordMutationType,
} from './__generated__/internalForgotPasswordMutation.graphql';

const mutation = graphql`
    mutation internalForgotPasswordMutation($input: RequestPasswordEmailInput!) {
        requestPasswordEmail(input: $input) {
            __typename
        }
    }
`;

export type InternalForgotPasswordInput = RequestPasswordEmailInput;

export async function internalForgotPasswordMutation(
    environment: Environment,
    input: InternalForgotPasswordInput
): Promise<internalForgotPasswordMutation$data> {
    return commitMutation<internalForgotPasswordMutationType>(environment, {
        mutation,
        variables: { input },
    });
}
