import type { FC } from 'react';
import serverVars from 'server-vars';

import { FormattedMessage } from 'dibs-react-intl';
import InternalLoginForm from './InternalLoginForm';
import InternalForgotPasswordForm from './InternalForgotPasswordForm';
import { LOGIN_FORM_TYPES } from '../helpers/loginValidation';

import '../styles/InternalLoginPage.scss';

type Props = {
    formType: LOGIN_FORM_TYPES;
};

const InternalLoginPage: FC<Props> = ({ formType }) => {
    return (
        <>
            {formType === 'login' && (
                <InternalLoginForm
                    successMessage={
                        !!serverVars.get('isLogoutPage') && (
                            <FormattedMessage
                                defaultMessage="You have been successfully logged out."
                                id="internalLogin.message.logout_success"
                            />
                        )
                    }
                />
            )}
            {formType === 'forgotPassword' && <InternalForgotPasswordForm />}
        </>
    );
};

export default InternalLoginPage;
