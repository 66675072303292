import { Environment, Network, RecordSource, Store } from 'relay-runtime';
import { createFetcher } from 'dibs-relay-network-layer/client';
import { getSellerToken, getInternalToken } from 'dibs-cookie-jar';

export function getEnvironment(
    type: 'internal' | 'seller' | 'seller-login' | 'internal-login'
): Environment {
    const source = new RecordSource();
    const store = new Store(source);
    let userType: 'internal' | 'seller' = 'internal';
    if (['seller', 'seller-login'].includes(type)) {
        userType = 'seller';
    }
    const network = Network.create(
        createFetcher({
            userType,
            getQueries: () => {
                if (type === 'internal') {
                    return getInternalToken(document.cookie);
                } else if (type === 'seller') {
                    return getSellerToken(document.cookie);
                }
                return {};
            },
        })
    );

    return new Environment({
        network,
        store,
    });
}
