import { FormattedMessage } from 'dibs-react-intl';
import { ReactNode } from 'react';
import { VerticalType } from '../dealer/mutations/__generated__/sellerRequestPasswordEmailMutation.graphql';

export type LOGIN_FORM_TYPES = 'login' | 'forgotPassword' | 'resetPassword';

export type VerticalInputValue = VerticalType | '';

export type InputValues = {
    email: string;
    password: string;
    password1: string;
    vertical: VerticalInputValue;
};

export type TouchedValues = {
    email: boolean;
    password: boolean;
    password1: boolean;
    vertical: boolean;
};

const matchingError = (
    <FormattedMessage
        id="login.reset_password_match_error"
        defaultMessage="The password does not match. Please edit and try submitting again."
    />
);
const requiredError = (
    <FormattedMessage id="login.field_required" defaultMessage="This field is required" />
);
const strengthError = (
    <FormattedMessage
        id="login.reset_password_strength_error"
        defaultMessage="Passwords must be at least 8 characters long and contain at least one capital letter and one number"
    />
);
const validEmailError = (
    <FormattedMessage
        id="login.invalid_email"
        defaultMessage="Please enter a valid email address."
    />
);

export type InputErrors = {
    email: ReactNode;
    password: ReactNode;
    password1: ReactNode;
    vertical: ReactNode;
};

function validateEmail(email: string): ReactNode {
    const validEmail = /.+@.+\..+/i;

    if (!email) {
        return requiredError;
    } else if (!validEmail.test(email)) {
        return validEmailError;
    }
    return null;
}

function validatePassword(password: string): ReactNode {
    if (!password) {
        return requiredError;
    }
    return null;
}

function validateNewPassword(password: string): ReactNode {
    const passwordStrength = /^(?=.*\d)(?=.*[A-Z]).{8,}$/;

    if (!password) {
        return requiredError;
    } else if (!passwordStrength.test(password)) {
        return strengthError;
    }
    return null;
}

function validatePassword1(password: string, password1: string): ReactNode {
    if (!password1) {
        return requiredError;
    } else if (password !== password1) {
        return matchingError;
    }
    return null;
}

function validateVertical(vertical: string): ReactNode {
    if (!vertical) {
        return requiredError;
    }
    return null;
}

export function validate(
    values: InputValues,
    formType: LOGIN_FORM_TYPES,
    isInternal: boolean
): { errors: InputErrors; isValid: boolean } {
    const errors: InputErrors = {
        email: null,
        password: null,
        password1: null,
        vertical: null,
    };

    switch (formType) {
        case 'login':
            errors.email = validateEmail(values.email);
            errors.password = validatePassword(values.password);
            break;
        case 'resetPassword':
            errors.password = validateNewPassword(values.password);
            errors.password1 = validatePassword1(values.password, values.password1);
            break;
        case 'forgotPassword':
            errors.email = validateEmail(values.email);
            if (!isInternal) {
                errors.vertical = validateVertical(values.vertical);
            }
            break;
    }

    let isValid = true;
    for (const val of Object.values(errors)) {
        if (val) {
            isValid = false;
        }
    }

    return { errors, isValid };
}
