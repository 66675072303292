import { Fragment, FunctionComponent, ReactNode, useState } from 'react';
import { useRelayEnvironment } from 'react-relay';
import LoginFormWrapper from '../LoginFormWrapper';
import LoginMessage from '../LoginMessage';
import LoginFormGroup from '../inputs/LoginFormGroup';
import LoginEmailInput from '../inputs/LoginEmailInput';
import LoginSubmit from '../inputs/LoginSubmit';
import InternalLoginDealerLink from './InternalLoginDealerLink';
import { useLoginFormState } from '../helpers/useLoginFormState';
import {
    internalForgotPasswordMutation,
    InternalForgotPasswordInput,
} from './mutations/internalForgotPasswordMutation';
import { internalForgotPasswordMutation as internalForgotPasswordMutationType } from './mutations/__generated__/internalForgotPasswordMutation.graphql';
import LoginServerErrorMessage from '../LoginServerErrorMessage';
import { useLoginMutation } from '../helpers/useLoginMutation';

type Props = {
    successMessage?: ReactNode;
};

const InternalForgotPasswordForm: FunctionComponent<Props> = () => {
    const { errors, isValid, values, touched, handleChange, handleFieldBlur } = useLoginFormState({
        formType: 'forgotPassword',
        isInternal: true,
    });
    const [success, setSuccess] = useState(false);
    const environment = useRelayEnvironment();
    const { errorCode, isSaving, handleSubmit } = useLoginMutation<
        internalForgotPasswordMutationType,
        InternalForgotPasswordInput,
        boolean
    >({
        isValid,
        mutationFn: internalForgotPasswordMutation,
        variables: {
            email: values.email,
        },
        getErrorCode: () => false, // no errorcodes from this mutation
        environment,
        handleFieldBlur,
        onSuccess: () => setSuccess(true),
    });

    let message;
    if (errorCode) {
        message = <LoginServerErrorMessage />;
    }

    if (success) {
        return (
            <LoginMessage
                messageType="success"
                message="You will receive an email with instructions to reset your password. Reminder: your www.1stdibs.com password and admin password will be the same."
            />
        );
    }

    return (
        <Fragment>
            {message && <LoginMessage messageType="error" message={message} />}
            <LoginFormWrapper heading="Password Reset" handleSubmit={handleSubmit}>
                <LoginFormGroup>
                    <LoginEmailInput
                        value={values.email}
                        touched={touched.email}
                        error={errors.email}
                        onBlur={handleFieldBlur}
                        onChange={handleChange}
                    />
                </LoginFormGroup>
                <LoginSubmit isSaving={isSaving} />
                <InternalLoginDealerLink />
            </LoginFormWrapper>
        </Fragment>
    );
};

export default InternalForgotPasswordForm;
