import { useState, FormEvent } from 'react';
import { Environment } from 'react-relay/legacy';
import { OperationType } from 'relay-runtime';
import { DibsRelayNetworkError } from 'dibs-relay-network-layer';

type MutationFunction<Mutation extends OperationType, Input> = (
    env: Environment,
    input: Input
) => Promise<Mutation['response']>;

type ErrorCodeAccessor<Mutation extends OperationType, ErrorCode> = (
    result: Mutation['response']
) => ErrorCode | null | undefined;

type useLoginMutationConfig<Mutation extends OperationType, Input, ErrorCode> = {
    isValid: boolean;
    handleFieldBlur: () => void;
    environment: Environment;
    variables: Input;
    mutationFn: MutationFunction<Mutation, Input>;
    onSuccess?: (arg: Mutation['response']) => void;
    getErrorCode?: ErrorCodeAccessor<Mutation, ErrorCode>;
};

type useLoginMutationReturnType<ErrorCode> = {
    errorCode: ErrorCode | DibsRelayNetworkError | false;
    hasSubmitted: boolean;
    isSaving: boolean;
    handleSubmit: (e: FormEvent<HTMLFormElement>) => Promise<void>;
};

export function useLoginMutation<Mutation extends OperationType, Input, ErrorCode>({
    isValid,
    mutationFn,
    variables,
    getErrorCode = () => null,
    environment,
    handleFieldBlur,
    onSuccess,
}: useLoginMutationConfig<Mutation, Input, ErrorCode>): useLoginMutationReturnType<ErrorCode> {
    const [errorCode, setError] = useState<ErrorCode | DibsRelayNetworkError | false>(false);
    const [hasSubmitted, setHasSubmitted] = useState(false);
    const [isSaving, setIsSaving] = useState(false);

    async function handleSubmit(e: FormEvent<HTMLFormElement>): Promise<void> {
        e.preventDefault();
        setError(false);
        setHasSubmitted(true);
        if (!isValid) {
            handleFieldBlur();
            return;
        }
        setIsSaving(true);
        try {
            const results = await mutationFn(environment, variables);
            const errorCodeResult = getErrorCode(results);
            if (errorCodeResult) {
                setError(errorCodeResult);
                setIsSaving(false);
                return;
            }
            if (onSuccess) {
                onSuccess(results);
            }
        } catch (err) {
            setIsSaving(false);
            setError(err as DibsRelayNetworkError);
        }
    }

    return { errorCode, hasSubmitted, isSaving, handleSubmit };
}
